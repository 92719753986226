import { useState } from "react";
import UseFetch from "../components/UseFetch";
import ModalAddAgent from "./ModalAddAgent";
import ModalEditeAgent from "./ModalEditeAgent";
import SearchField from "../components/SeachField";

const Agents = () => {
    const { data: agents, isloading } = UseFetch('agents');
    const [selected, setSelected] = useState(null);

    return (
        isloading ? <div className="loader"></div> : <div className="agent">
            <div className="row justify content between align-items-center">
                <div className="col-lg-8 text-primary h3">GESTION DES AGENTS</div>
                <div className="col-lg-4 text-end">
                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addAgent">Ajouter</button>
                </div>
            </div>

            {agents?.length > 0 ? <div className="table-responsive">
                <div className="col-lg-4 form-group my-2 ms-auto">
                    <SearchField placeholder="Rechercher un agegnt" plage='plage' conteneur='tr' critere='critere' />
                </div>
                <table className="table table-hover table-striped table-bordered">
                    <thead className="table-primary">
                        <tr>
                            <th>N°</th>
                            <th>PRENOMS ET NOM</th>
                            <th>ADRESSE</th>
                            <th>TELEPHONE</th>
                            <th className="text-end">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="plage">
                        {
                            agents?.map((agent, ind) => (
                                <tr key={ind} className="tr">
                                    <td>{ind + 1}</td>
                                    <td className="critere">{agent.prenom} {agent.nom}</td>
                                    <td>{agent.adresse}</td>
                                    <td>{agent.telephone}</td>
                                    <td className="text-end">
                                        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editeAgent" onClick={() => setSelected(agent)}>
                                            <i className="bx bx-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div> : <div className="row justify-content-center p-2 fs-3 bg-danger-subtle text-danger">Aucun agent trouvé!</div>
            }
            <ModalAddAgent url='agents' />
            <ModalEditeAgent agent={selected} url='agent' />
        </div>
    );
}

export default Agents;