
import { defaulUrl, decryptData } from "../components/const";
import UseFetch from "../components/UseFetch";
import { useState } from "react";
import Swal from "sweetalert2";
import AddUser from "./AddUser";
import SearchField from "../components/SeachField";
const Users = () => {
    const { data: users, isloading } = UseFetch('users');
    let [isLoading, setIsLoading] = useState(false);
    let status = 0;
    const user = decryptData("USER");

    const handleActiveUser = (e, id, opt, statut) => {
        e.preventDefault();
        Swal.fire({
            title: `${opt} un compte utilisateur`,
            text: `Voulez-vous ${opt} ce utilisateur?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                const form_data = new FormData();
                form_data.append('statut', statut);
                setIsLoading(true);
                fetch(`${defaulUrl}users/activation/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    },
                    body: form_data
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {
                    setIsLoading(false)
                    if (status === 200) {
                        Swal.fire({
                            title: `succès`,
                            text: data.message,
                            icon: "success"
                        })
                    }
                })
            }
        })
    }

    const ouvrirFeremerSession = (opt) => {
        Swal.fire({
            title: `Gestion des sessions`,
            text: `Voulez-vous ${opt} les sessions des vendeurs?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                setIsLoading(true);
                fetch(`${defaulUrl}session/${opt}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    },
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {
                    setIsLoading(false)
                    if (status === 200) {
                        Swal.fire({
                            title: `succès`,
                            text: data.message,
                            icon: "success"
                        })
                    }
                })
            }
        })
    }

    return (isloading ? <div className="loader"></div> :
        <div className="Users contanier-fluid">
            <div className="row justify-content-between p-2 mb-2">
                <div className="col-lg-8 ">
                    <h2>Gestion des utilisateurs</h2>
                </div>
                {user.droit === 'Administrateur' && <div className="col-lg-3 text-end">
                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalUser">Nouveau utilisateur</button>
                </div>}
            </div>

            <div className="row p-2 my-2">
                <div className="row justify-content-end m-0">
                    {user.droit === 'Comptable' && <div className="col-lg-8 modal-footer justify-content-start">
                        <button className='btn btn-danger m-1' onClick={() => ouvrirFeremerSession('fermer')}
                        >Fermer les sessions vendeurs</button>
                        <button className='btn btn-success m-1' onClick={() => ouvrirFeremerSession('ouvrir')}
                        >Ouvrir les sessions vendeurs</button>

                    </div>}
                    <div className="col-lg-4 py-2">
                        <SearchField placeholder='Rechercher' plage='plage' conteneur='tr' critere='critere' />
                    </div>
                </div>
                <div className="row">
                    <div className="col table-responsive">
                        {isLoading && <div className="loader"></div>
                        }
                        <table className="table table-white shadow table-bordered table-hover table-striped ">
                            <thead className="table-dark">
                                <tr>

                                    <th >PRENOMS ET NOM</th>
                                    <th>EMAIL</th>
                                    <th>TELEPHONE</th>
                                    <th>DROIT</th>
                                    <th>MAGASIN</th>
                                    <th>STATUT</th>
                                    <th>CONNEXION</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {users?.length ? users.map((user, index) => (
                                    <tr key={user.id} className="tr">

                                        <td className="critere">{user.firstname} {user.lastname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.droit}</td>
                                        <td>{user.magasin}</td>
                                        <td>{user.statut}</td>
                                        <td>{`${user.isconnected ? 'en ligne' : 'hors ligne'}`}</td>
                                        <td className="text-end">
                                            {(user.statut === 'Bloqué' || user.statut === 'Fermé') && <button className="btn btn-success m-1"
                                                onClick={(e) => handleActiveUser(e, user.id, 'activer', 'Actif')} title="activer"> <i className="bx bx-check-circle fs-4"></i>
                                            </button>}
                                            {user.statut === 'Actif' && <button className="btn btn-danger m-1"
                                                onClick={(e) => handleActiveUser(e, user.id, 'bloquer', 'Bloqué')} title="fermer/bloquer"> <i className="bx bx-x-circle fs-4"></i>
                                            </button>}
                                            {user.isconnected === 1 && <button className="btn btn-danger m-1"
                                                onClick={(e) => handleActiveUser(e, user.id, 'déconnecter', 'Actif')} title="déconnecter"> <i className="bx bx-exit fs-4"></i>
                                            </button>}

                                        </td>
                                    </tr>
                                ))
                                    : <tr >
                                        <td colSpan="9" className="p-3 text-center bg-danger-subtle text-primary w-100">
                                            Aucun utilisateur trouve
                                        </td>
                                    </tr>
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>

            <AddUser />
        </div>

    );
}

export default Users;