import { useEffect, useState } from "react";
import { decryptData, deleter } from "../components/const";
import UseFetch from "../components/UseFetch";
import EditeVersement from "./EditeVersement";

const ListeVersement = () => {
    const { data, isloading } = UseFetch('versements');
    const profile = decryptData('user_profile');
    let versements = data?.filter((p) => (+p.idmagasin) === (+profile.idmagasin))
    versements?.sort((a, b) => (a.statut - b.statut))
    const [totalSolde, setTotalSolde] = useState(0);
    const [totalverse, setTotalVerse] = useState(0);
    const [selectvers, setSelectVers] = useState(null);
    const user = decryptData("USER");
    useEffect(() => {
        if (versements?.length) {
            setTotalSolde(versements?.reduce((previous, current) => { return previous + current.solde }, 0))
            setTotalVerse(versements?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [versements])

    return (

        isloading ? <div className="loader"></div> : versements?.length ?
            <div className="liste">
                <h5 className="text-primary">LISTE DES VERSEMENTS EFFECTUES</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-hover table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>MAGASIN</th>
                                <th>SOLDE</th>
                                <th>MONTANT VERSE</th>
                                <th>DATE</th>
                                <th>STATUT</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                versements?.map((vers, ind) => (
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{vers.magasin}</td>
                                        <td>{vers.solde.toLocaleString()} GNF</td>
                                        <td>{vers.montant.toLocaleString()} GNF</td>
                                        <td>{ vers.date}</td>
                                        <td>{vers.status}</td>
                                        <td className="text-end">
                                            {(vers.status != 'confirmé' && user.droit === "Magasin" &&
                                                vers.duree < 2) && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeVers' onClick={() => setSelectVers(vers)}>
                                                    <i className="bx bx-edit"></i>
                                                </button>
                                            }
                                            {(vers.status != 'confirmé') && <button className="btn btn-danger m-1" onClick={() => deleter(`versement/delete/${vers.id}`)}><i className="bx bx-trash"></i></button>}
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="bg-secondary-subtle fw-bold">
                                <td colSpan={2} className="text-center ">TOTAUX</td>
                                <td>{totalSolde.toLocaleString()} GNF</td>
                                <td>{totalverse.toLocaleString()} GNF</td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <EditeVersement paie={selectvers} />
            </div> : <div className="w-100 bg-danger-subtle text-danger text-center fs-3 p-2">Aucun versement effectue</div>
    );
}

export default ListeVersement;