import { useLocation } from "react-router-dom";
import ModalRemboursementFournisseur from "../Comptability/ModalRemboursementFournisseur";
import { useEffect, useState } from "react";
import { decryptData } from "../components/const";
import Paiements from "../Clients/Paiements";
const CompteFournisseur = () => {
    const user = decryptData("USER")
    const [selectedFacture, setFacture] = useState(null)
    const [total, setTotal] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0)
    const handleSetSelect = (fact) => {
        setFacture(fact)
    }
    const location = useLocation();
    const fournsseur = location?.state?.fourn;

    // useEffect(() => {
    //     setTotal(fournsseur?.conteneurs?.reduce((previous, current) =>  { return previous + current?.prix_total }, 0))
    // }, [])
    return (
        <div className="compte">
            <h4>COMPTE FOURNISSEUR: {`${fournsseur.prenoms} ${fournsseur.nom}`} </h4>

            <div className="table-responsive ">
                {
                    fournsseur?.conteneurs?.length > 0 ? <table className="table table-white shadow table-bordered table-hover table-striped border-primary">
                        <thead className="table-dark border-primary">
                            <tr className="text-center">
                                <th >N°CONT</th>
                                <th>CONTENEURS</th>
                                <th> MONTANT</th>
                                <th> PAYE</th>
                                <th> RESTE</th>
                                <th>DATE</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">

                            {
                                fournsseur?.conteneurs?.map((cont, ind) => (
                                    <tr key={ind}>
                                        <td>{cont.numero}</td>
                                        <td>{cont.lib_conteneur}</td>
                                        <td>{cont.prix_total?.toLocaleString()} {cont.devise}</td>
                                        <td>{cont.montant_paye?.toLocaleString()} {cont.devise}</td>
                                        <td>{(cont.prix_total - cont.montant_paye)?.toLocaleString()} {cont.devise}</td>
                                        <td>{cont.created_at}</td>
                                        <td className="text-end">
                                            {user.droit === 'Comptable' && <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#paiement" onClick={() => handleSetSelect(cont)}><i className="bx bx-show"></i></button>}
                                        </td>
                                    </tr>
                                ))
                            }
                            {/* <tr className="fw-bold bg-secondary-subtle">
                                <td colSpan={2} className="text-center">TOTAUX</td>
                                <td >{total?.toLocaleString()} GNF</td>

                            </tr> */}
                            
                        </tbody>
                    </table> : <div className="bg-danger-subtle fs-4 text-danger p-2 text-center">Aucune facture pour ce fournisseur!</div>
                }
            </div>
            <Paiements paiements={selectedFacture?.paiements} />

        </div>
    );
}

export default CompteFournisseur;