import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";
const ModalAvanceClient = ({ client }) => {
    const [paye, setPaye] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const ref = useRef();
    const handlePayer = (e) => {
        e.preventDefault();
        let status = 0;
        setIsLoading(true)
        const formData = new FormData();
        formData.append('montant',paye);
        formData.append('idcompte',client.idcompte);
        fetch(`${defaulUrl}versements/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    setPaye(0);
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade " id="modalAvance" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Saisir une avance</h1>

                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <h4 className="px-2 bg-secondary-subtle"> Client: {client?.client}</h4>
                    <form >
                        <div className="modal-body position-relative">
                            <label htmlFor="" className="fs-5">Montant versé</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={paye}
                                        onChange={(e) => {
                                            setPaye(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)}
                                    />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                }
                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="button" className="btn btn-success" onClick={(e) => handlePayer(e)} >Valider</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalAvanceClient;