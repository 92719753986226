import { useEffect, useState } from "react";
import UseFetch from "../components/UseFetch";
import ModalAddAgent from "./ModalAddAgent";
import ModalEditeAgent from "./ModalEditeAgent";
import { decryptData } from "../components/const";
import { NavLink } from "react-router-dom";

const Transitaires = () => {
    const { data: agents, isloading } = UseFetch('transitaires');
    const [selected, setSelected] = useState(null);
    const user = decryptData("USER");
    const [total, setTotal] = useState(0)
    useEffect(() => {
        setTotal(agents?.reduce((previous, current) => { return previous + current.solde }, 0))
    }, [agents])
    return (
        isloading ? <div className="loader"></div> : <div className="agent">

            <div className="row justify content between align-items-center">
                <div className="col-lg-8 text-primary h3">GESTION DES TRANSITAIRES</div>
                <div className="col-lg-4 text-end">
                    {user.droit === 'Administrateur' && <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addAgent">Ajouter</button>}
                </div>
            </div>

            {agents?.length > 0 ? <div className="table-responsive">
                <div className="col-lg-4 form-group my-2 ms-auto">
                    <label htmlFor="">Rechercher </label>
                    <input type="search" className="form-control" placeholder="Rechercher un client" />
                </div>
                <table className="table table-hover table-striped table-bordered">
                    <thead className="table-primary">
                        <tr>
                            <th>N°</th>
                            <th>PRENOMS & NOM</th>
                            <th>ADRESSE</th>
                            <th>TELEPHONE</th>
                            <th>SOLDE</th>
                            <th className="text-end">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="plage">
                        {
                            agents?.map((agent, ind) => (
                                <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{agent.prenom} {agent.nom}</td>
                                    <td>{agent.adresse}</td>
                                    <td>{agent.telephone}</td>
                                    <td>{agent.solde?.toLocaleString()}</td>
                                    <td className="text-end">
                                        {user.droit === 'Administrateur' &&
                                            <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#editeAgent" onClick={() => setSelected(agent)}>
                                                <i className="bx bx-edit"></i>
                                            </button>
                                        }
                                        <NavLink className="btn btn-primary m-1" to={`/transitaire`} state={{ agent }}>
                                            <i className="bx bx-show"></i>
                                        </NavLink>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className="fw-bold bg-secondary-subtle ">
                            <td className="text-center" colSpan={4}>TOTAUX</td>
                            <td>{total?.toLocaleString()} GNF</td>
                        </tr>
                    </tbody>
                </table>
            </div> : <div className="row justify-content-center p-2 fs-3 bg-danger-subtle text-danger">Aucun agent transitaire trouvé!</div>
            }
            <ModalAddAgent url='transitaires' />
            <ModalEditeAgent agent={selected} url='transitaire' />
        </div>
    );
}
export default Transitaires;