import { useLocation } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { useEffect, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import EditePaieFacture from "../Ventes/EditePaieFacture";

const DetailPaiement = () => {
    const location = useLocation();
    const facture = location.state.facture;
    const [selectedFact, setSelectedFact] = useState(facture ?? null);
    const [total, setTotal] = useState(0);
    const { data, isloading } = UseFetch(`magasin/${facture.idmagasin}/client/${facture.id}/factures`);
    const [isLoading, setIsLoading] = useState(false);
    const [totalqte, setTotalQte] = useState(0);
    const [totalmontant, setTotalMontant] = useState(0);
    const [produits, setProduits] = useState(null)
    const [paiements, setPaiements] = useState(null)
    const [selectPaie, setSelectPaie] = useState(null)
    const factures = data?.factures;
    const handleDetail = () => {
        setIsLoading(true)
        fetch(`${defaulUrl}factures/${selectedFact.idfacture}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setProduits(data?.produits);
            setPaiements(data?.paiements);
        })

    }
    useEffect(() => {
        if (produits) {
            setTotalQte(produits?.reduce((previous, current) => { return previous + current.quantite }, 0));
            setTotalMontant(produits?.reduce((previous, current) => { return previous + (current.pvu * current.quantite) }, 0));
        }
    }, [produits])


    useEffect(() => {
        handleDetail()
    }, [selectedFact])


    useEffect(() => {
        if (paiements?.length) {
            setTotal(paiements?.reduce((previous, current) => { return previous + current.montant }, 0));
        }
    }, [paiements])
    const user = decryptData("USER");
    return (
        <div className="detail container-fluid">
            <h5 className="text-primary text-center">DETAILS  DE LA FACTURE N°{selectedFact.numero}</h5>

            <div className="row my-3">
                <hr />
                <div className="col-lg-6">
                    <div>  <span className="fw-bold mx-2">Client</span>: {facture?.client?.toUpperCase()}</div>
                    <div><span className="fw-bold mx-2">Telephone</span>: {facture?.telephone?.toUpperCase()}</div>
                </div>
                <div className="col-lg-6">
                    <div>  <span className="fw-bold mx-2">Agent responsable</span>: {facture?.client?.toUpperCase()}</div>
                    <div><span className="fw-bold mx-2">Telephone</span>: {facture?.telephone?.toUpperCase()}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 py-2 border-end shadow rounded card">
                    <span className="text-secondary fst-italic mt-auto py-1">Cliquez sur une facture pour voir les détails!</span>
                    <div className="list-group    py-1 lef-liste">
                        {
                            factures?.map((fact, ind) => (
                                <button key={ind} className="list-group-item list-group-item-action list-group-item-primary px-1" onClick={() => setSelectedFact(fact)}>
                                    <small className="fw-bold">  {fact.numero}({fact.created_at})</small>
                                </button>
                            ))
                        }
                        {isloading && <div className="text-center w-100">
                            <Loading />
                        </div>}

                    </div>

                </div>
                <div className="col-lg-8 container-fluid  p-2">
                    {
                        isLoading ? <div className="loader"></div> :
                            <>
                                <div className="row">
                                    <h6 className="">LISTE DES PRODUITS</h6>
                                    <div className="table-responsive">
                                        <table className="table-table-hover table-bordered table striped">
                                            <thead className="table-primary text-center">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>PRODUIT</th>
                                                    <th>QUANTITE</th>
                                                    <th>PVU</th>
                                                    <th>MONTANT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    produits?.map((prod, ind) => (
                                                        <tr key={ind}>
                                                            <td>{ind + 1}</td>
                                                            <td>{prod.produit}</td>
                                                            <td>{prod.quantite}</td>
                                                            <td>{prod.pvu.toLocaleString()}</td>
                                                            <td>{(prod.pvu * prod.quantite).toLocaleString()}</td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr className="fw-bold bg-secondary-subtle">
                                                    <td colSpan={2} className="text-center">Totaux</td>
                                                    <td>{totalqte.toLocaleString()}</td>
                                                    <td></td>
                                                    <td>{totalmontant.toLocaleString()} GNF</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr />

                                <div className="row mt-2">
                                    <h6 className="">LISTE DES PAIEMENTS</h6>
                                    {paiements?.length > 0 ? <div className="table-responsive">
                                        <table className="table-table-hover table bordered table striped">
                                            <thead className="table-success">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>MONTANT</th>
                                                    <th>SOURCE</th>
                                                    <th>DATE</th>
                                                    {user.droit === "Magasin" && <th>ACTION</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    paiements?.map((paie, ind) => (
                                                        <tr key={ind}>
                                                            <td>{ind + 1}</td>
                                                            <td>{paie?.montant?.toLocaleString()} GNF</td>
                                                            <td>{paie?.source}</td>
                                                            <td>{paie?.created_at}</td>
                                                            {user.droit === "Magasin" && <td className="text-end">
                                                                {paie.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaie' onClick={() => setSelectPaie(paie)}>
                                                                    <i className="bx bx-edit"></i>
                                                                </button>}
                                                            </td>}
                                                        </tr>
                                                    ))
                                                }
                                                <tr className="bg-secondary-subtle">
                                                    <td className="fs-5 fw-bold text-center">Total</td>
                                                    <td className="fw-bold">{total.toLocaleString()} GNF</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center fs-4">Aucun paiement effectué pour cette facture!</div>}
                                </div>
                            </>

                    }
                </div>
            </div>

            <EditePaieFacture paie={selectPaie} />
        </div>
    );
}

export default DetailPaiement;