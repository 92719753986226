import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const PayerDette = ({ dette }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [montant, setMontant] = useState(0)
    const ref = useRef()

    const handleSubmite = (e) => {
        e.preventDefault();
        if (!montant) {
            setErrors({'montant':"Entrez le montant payé par le client"});
            return;
        }
        if (montant>(+dette.reste)) {
            setErrors({'montant':"Vous avez saisi un montant superieur a la dette"});
            return;
        }
        setIsLoading(true)
        const formdata = new FormData(e.target);
        formdata.append('iddetteinitial', dette.iddette);
        formdata.append('montant', montant);
        let status = 0;
        fetch(`${defaulUrl}dettes/rembourser`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.log(data);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade " id="modalverser" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Remboursement de Dette</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)} >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Client</label>

                                <input type="text" className="form-control" value={(dette?.client)} disabled />
                               
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant à payer</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={(+dette?.reste).toLocaleString()} disabled />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant payé</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={montant}
                                        onChange={(e) => {
                                            setMontant(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)}
                                    />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                {errors?.montant && <span className="text-danger mt-2"> {errors?.montant}</span>
                                }
                            </div>
                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="submite" className="btn btn-success"  >Valider</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default PayerDette;