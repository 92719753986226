const Personnel = () => {
    return (
        <div className="personnel">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">Liste</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Présence</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Salaire & Primes</a>
                </li>
            </ul>
        </div>
    );
}

export default Personnel;