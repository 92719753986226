import { NavLink } from "react-router-dom";
import { handleMenu } from "./menu";
import { handeLeaveSideBar, handeOnHoverSideBar } from "../components/const";
const MenuMagasin = () => {
    return (
        <ul className="metismenu " id="menu" onMouseOver={() => handeOnHoverSideBar()} onMouseLeave={() => handeLeaveSideBar()}>
            <li>
                <NavLink to='/'>
                    <div className="parent-icon"><i className='bx fs-4 bx-home-circle'></i>
                    </div>
                    <div className="menu-title">Tableau de bord</div>
                </NavLink>
            </li>
            <li className="dropdown" >
                <a className=" bg-secondary-subtle d-flex align-items-center h-100 p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title"> Gestion vente</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/facturation'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Facturation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/vente-du-jour'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Liste des ventes
                        </NavLink>
                    </li>
                    <NavLink to='/vente-conteneur'>
                        <i className="bx fs-4 bx-right-arrow-alt">
                        </i>vente/conteneur
                    </NavLink>
                    <li hidden>
                        <NavLink to='approvisions' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Vente prod/conteneur
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/factures/suppression' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Suppression factures
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdown">
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon">

                        <i className="bx fs-4 bx-category ms-2"></i>
                    </div>
                    <div className="menu-title">
                        Stocks & Appro
                    </div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/magasins/stocks'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Gestion stocks
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/approvisions'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Approvisions
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdown">
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title">Clients</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/clients' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/clients/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Clients en dette
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/ventes/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/clients/dette'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Dettes initiales
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdown">
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title"> Comptabilité</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/recouvrements'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Recouvrements client
                        </NavLink>
                    </li>
                    <li >
                        <NavLink to='/versements' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Liste versements
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/caisse'>
                            <div className="parent-icon">
                                <i className="bx fs-4 bx bx-line-chart"></i>
                            </div>
                            <div className="menu-title">Rapport</div>
                        </NavLink>
                    </li>
                </ul>
            </li>

        </ul>
    );
}

export default MenuMagasin;