import { NavLink, useNavigate } from "react-router-dom";
import { defaulUrl, encryptData, handleShowPwd, Loading } from "../components/const";
import { useState } from "react";
import Swal from "sweetalert2";
const Login = () => {
    let [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const handLogin = (e) => {
        e.preventDefault();
        let status = 0;
        setIsLoading(true)
        const formData = new FormData(e.target);
        fetch(`${defaulUrl}login`, {
            method: "POST",
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                encryptData('ACCESS_TOKEN', data.token);
                encryptData('USER', data.user);
                encryptData('user_profile', data.profil);
                navigate('/');
            } else {

                if (data?.errors?.blocage) {
                    Swal.fire({
                        title: "Connexion impossible!",
                        text: data?.errors?.blocage,
                        icon: 'error'
                    })
                }
                setErrors(data.errors);


            }
        });
    }
    localStorage.clear();
    return (
        <div className="Login bg-login">
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="mb-4 text-center">
                                    <img src="assets/images/logo-img.png" width="180" alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="login-separater text-center mb-4"> <span>VOTRE EMAIL & MOT DE PASSE</span>
                                                <hr />
                                            </div>
                                            <div className="form-body">
                                                <form className="row g-3" onSubmit={(e) => handLogin(e)}>
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="form-label"> Adresse Email</label>
                                                        <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" />
                                                    </div>
                                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                                    <div className="col-12">
                                                        <label htmlFor="password" className="form-label">Mot de passe</label>
                                                        <div className="input-group show_hide_password" id="">
                                                            <input type="password" className="form-control border-end-0" id="password" placeholder="Entrez votre mot de passe " name="password" /> <a onClick={handleShowPwd} className="input-group-text bg-transparent btn btn-primary text-dark"><i className='bx bx-hide'></i></a>
                                                        </div>
                                                    </div>
                                                    {errors.password && <span className="text-danger">{errors.password}</span>}
                                                    <div className="col-md-12 text-end">	<NavLink to='/password' >Mot de passe oublié ?</NavLink>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary d-flex align-items-center justify-content-center">
                                                                {isLoading && <span><Loading /></span>}
                                                                <i className="bx bxs-lock-open"> </i>
                                                                Me Connecter</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;