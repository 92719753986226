import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Paiements from "../Clients/Paiements";

const CompteTransitaire = () => {
    const location = useLocation()
    const transitaire = location.state.agent;
    const [paiments, setPaiements] = useState([]);
    const [totalPaye, setTotal] = useState(0)
    useEffect(() => {
        setTotal(transitaire?.factures?.reduce((previous, current) => { return previous + current.paye }, 0))
    }, [])
    return (
        <div className="transit">
            <h4>COMPTE TRANSITAIRE: <span className="text-dark"> {transitaire.prenom} {transitaire.nom}</span></h4>

            {transitaire?.factures?.length > 0 ?

                <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered">
                        <thead className="table-dark text-center">
                            <tr>
                                <th>N°</th>
                                <th>CONTENEUR</th>
                                <th>MONTANT</th>
                                <th>PAYE</th>
                                <th>RESTE</th>
                                <th>DATE</th>
                                <th className="text-end">ACTION</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                transitaire?.factures?.map((fac, ind) => (
                                    <tr key={ind} className="align-middle">
                                        <td>{fac.numero}</td>
                                        <td>{fac.conteneur.toUpperCase()}</td>
                                        <td>{fac.montant.toLocaleString()}</td>
                                        <td>{fac.paye.toLocaleString()}</td>
                                        <td>{fac.reste.toLocaleString()}</td>
                                        <td>{fac.created_at}</td>
                                        <td className="text-end">
                                            <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#paiement' onClick={() => setPaiements(fac.paiements)}>
                                                <i className="bx bx-show"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="fw-bold bg-secondary-subtle">
                                <td colSpan={2} className="text-center">TOTAUX</td>
                                <td>{(totalPaye + transitaire?.solde).toLocaleString()} GNF</td>
                                <td>{totalPaye?.toLocaleString()} GNF</td>
                                <td>{(transitaire.solde)?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table>
                </div> : <div className="text-center p-2 fs-4 text-danger bg-danger-subtle">
                    Aucune facture pour cet agent!
                </div>
            }

            <Paiements paiements={paiments} />
        </div>
    );
}

export default CompteTransitaire;