import { useParams } from "react-router-dom";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";

import ContextMenuFacture from "./ContextMenuFacture";
const ModalFacturesClient = () => {
    const { idmag, id } = useParams();
    let { data, isloading } = UseFetch(`magasin/${idmag}/client/${id}/factures`)
    const client = data?.client;
    let factures = data?.factures;
    const totaux = data?.totaux;
    factures?.sort((a, b) => (b.reste - a.reste));
    return (
        <div className="detail">

            <div className="modal-body py-2">

                {
                    isloading ? <Loading /> : factures?.length ?

                        <div className="row">
                            <h4>LISTE DES FACTUTES</h4>
                            <div className="table-responsive mt-2">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>N°</th>
                                            <th>N°FACTURE</th>
                                            <th>MONTANT</th>
                                            <th>REMISE</th>
                                            <th>MONTANT NET</th>
                                            <th>PAYE</th>
                                            <th>RESTE</th>
                                            <th>CONTENEUR</th>
                                            <th>VENDEUR</th>
                                            <th>DATE</th>
                                            <th className="text-end">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            factures.map((fact, ind) => (
                                                <tr key={ind} className={`align-middle ${fact?.suppression ? "bg-danger-subtle" : ""}`}>
                                                    <td>{ind + 1}</td>
                                                    <td>{fact.numero}</td>
                                                    <td>{fact.total.toLocaleString()}</td>
                                                    <td>{(+fact.remise).toLocaleString()}</td>
                                                    <td>{(+fact.montant).toLocaleString()}</td>
                                                    <td>{(+fact.paye).toLocaleString()}</td>
                                                    <td>{(+fact.reste).toLocaleString()}</td>
                                                    <td>{(fact.conteneur)?.toUpperCase()}</td>
                                                    <td>{(fact.vendeur)?.toUpperCase()}</td>
                                                    <td>{fact.created_at}</td>
                                                    <td className="text-end">
                                                        <ContextMenuFacture facture={fact} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="table-secondary fw-bold " >
                                            <td colSpan={2} className=" text-center ">TOTAUX </td>
                                            <td>{(+totaux.total).toLocaleString()} </td>
                                            <td>{(+totaux.remise).toLocaleString()} </td>
                                            <td>{(+totaux.montant).toLocaleString()} </td>
                                            <td>{(+totaux.paye).toLocaleString()} </td>
                                            <td>{(+totaux.reste).toLocaleString()} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <div className="row p-2 text-danger bg-danger-subtle justify-content-center">Aucune facture pour ce client</div>
                }

            </div>
        </div>

    );
}

export default ModalFacturesClient;