import { Loading, decryptData, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";
import { NavLink } from "react-router-dom";
import SearchField from "../components/SeachField";
import { useState } from "react";
import Swal from "sweetalert2";
import DetailConteneur from "./DetailConteneur";
const Contenaires = () => {
    let { data: conteneurs, isloading } = UseFetch('conteneurs/liste');
    let [isLoading, setIsLoading] = useState(false);
    let [detailproduct, setDetailProduct] = useState([]);
    let [date1, setDate1] = useState(null);
    let [date2, setDate2] = useState(null);
    const [trieConteneurs, setTrie] = useState(null);
    const user = decryptData('USER');
    const isAdmin = user.droit === 'Administrateur';



    const handleDetail = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}stocks/liste/${+id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setDetailProduct(data)
        })

    }

    const handleTriByDate = (e) => {

        if (!date1 || !date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "Choisissez de bonnes dates pour date1 et date 2 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });

            return;
        }


        e.preventDefault();
        setTrie(conteneurs.filter((conteneur) => conteneur.created_at >= date1 && conteneur.created_at <= date2 + 1));

    }
    const handleDelete = (id) => {
        Swal.fire({
            title: "Confirmez votre opération",
            text: 'Souhaitez-vous vraiment supprimer ce conteneur?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "green"
        }).then((rep) => {
            if (rep.isConfirmed) {
                isloading = true;
                fetch(`${defaulUrl}conteneurs/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                    }
                }).then((response) => {
                    return response.json();
                }).then(data => {
                    Swal.fire({
                        title: "Succès",
                        text: data.message,
                        icon: 'success',
                        timer: 5000
                    })
                })
            }
        })
    }
    conteneurs = conteneurs?.sort((a, b) => a.statut - b.statut)
    return (
        <div className="Conteneur container-fluid ">
            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    GESTION DES CONTENEURS
                </div>
                {isAdmin && <div className="col-lg-4 text-end">
                    <NavLink to='/conteneurs/add' type="button" className="btn btn-primary ">Nouveau conteneur</NavLink>
                </div>}
            </div>
            {
                isloading ? <Loading /> :
                    conteneurs?.length ?

                        <div className="row">
                            <div className="row justify-content-between m-0 align-items-center">

                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="form-group  col-5">

                                            <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                                        </div>
                                        <div className="form-group col-5">
                                            <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                                        </div>
                                        <button onClick={(e) => handleTriByDate(e)} type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search" ></i></button>
                                    </div>

                                </div>

                                <div className="col-lg-4">
                                    <SearchField plage='plage' conteneur='tr' critere='conteneur' />
                                </div>
                            </div>
                            <div className="table-responsive ">
                                <table className="table table-white shadow table-bordered table-hover table-striped border-primary">
                                    <thead className="table-dark border-primary">
                                        <tr className="text-center">
                                            <th >N°CONT</th>
                                            <th>CONTENEURS</th>
                                            <th>FOURNISEUR</th>
                                            <th>TELEPHONE</th>
                                            <th> STATUT</th>
                                            <th>DATE AJOUT</th>
                                            <th className="text-end">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody className="plage">
                                        {
                                            trieConteneurs ? trieConteneurs.map((cont, ind) => (
                                                <tr key={cont.id} className="tr">
                                                    <td >{cont.numfact}</td>
                                                    <td >{cont.conteneur}</td>
                                                    <td>{cont.Fournisseur}</td>
                                                    <td>{cont.telephone}</td>
                                                    <td className="conteneur">{cont.statut ? 'Mis en approvision' : 'Disponible'}</td>
                                                    <td >
                                                        {cont.created_at}
                                                    </td>
                                                    <td className="text-end">
                                                        <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#detailConteneur" onClick={(e) => handleDetail(e, cont.id)}><i className="bx bx-show"></i></button>

                                                        <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#detailConteneur" onClick={(e) => handleDetail(e, cont.id)}><i className="bx bx-check-circle"></i> confirm</button>
                                                        {(cont.statut === 0) && <button className="btn btn-danger m-1" onClick={() => handleDelete(cont.id)}><i className="bx bx-trash"></i></button>}
                                                    </td>
                                                </tr>
                                            )) :
                                                conteneurs.map((cont) => (
                                                    <tr key={cont.id} className="tr">

                                                        <td >{cont.numfact}</td>
                                                        <td>{cont.conteneur}</td>
                                                        <td>{cont.Fournisseur}</td>
                                                        <td>{cont.telephone}</td>
                                                        <td className="conteneur">{cont.statut ? 'Mis en approvision' : 'Disponible'}</td>
                                                        <td >
                                                            {cont.created_at}
                                                        </td>
                                                        <td className="text-end">
                                                            <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#detailConteneur" onClick={(e) => handleDetail(e, cont.id)}><i className="bx bx-show"></i></button>

                                                           

                                                            {(cont.statut === 0) && <button className="btn btn-danger m-1" onClick={() => handleDelete(cont.id)}><i className="bx bx-trash" ></i></button>}
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>



                        : <div className="row fs-5 py-2 justify-content-center bg-danger-subtle text-danger fs-3">
                            Aucun conteneur enregistré!
                        </div>
            }

            <DetailConteneur isLoading={isLoading} detailproduct={detailproduct} />

        </div >
    );
}

export default Contenaires;