
const SearchField = ({ plage, conteneur, critere, placeholder }) => {
    const handleSearch = (e) => {
        if (plage && conteneur && critere) {
            let myPlage = document.querySelector(`.${plage}`);
            let myconteneur = myPlage.querySelectorAll(`.${conteneur}`)
            let valeur = ''
            let saisie = ''
            if (e.target.value) {
                saisie = e.target.value.toLowerCase()
                myconteneur.forEach(elm => {
                    valeur = elm.querySelector(`.${critere}`)?.innerText?.toLowerCase()
                    if (!valeur?.includes(saisie)) {
                        elm.setAttribute('hidden', 'true')
                    } else {
                        elm.removeAttribute('hidden')
                    }
                })
            } else {
                myconteneur.forEach(elm => {

                    elm.removeAttribute('hidden')

                })
            }
        }

    }
    return (
        <div className="SearchField py-3">
            <input type="search" className="form-control h-100 p-3" placeholder={placeholder ? placeholder : 'Rechercher'} onChange={(e) => handleSearch(e)} />
        </div>
    );
}

export default SearchField;