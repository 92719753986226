import { useEffect, useRef, useState } from "react";
import UseFetch from "../components/UseFetch";
import { impression } from "../components/const";
const Benefice = () => {
    const { data: benefice, isloading } = UseFetch('admin/benefice')

    const [total, setTotal] = useState(0)
    const [totalfacture, setTotalFacture] = useState(0)
    const [totaldepense, setTotalDepense] = useState(0)
    const [totalbenefice, setTotalBenefice] = useState(0)
    const printElement = useRef()
    useEffect(() => {
        if (benefice) {
            setTotal(benefice?.reduce((previous, current) => { return previous + current.montant_achat }, 0))
            setTotalFacture(benefice?.reduce((previous, current) => { return previous + current.montant }, 0))
            setTotalDepense(benefice?.reduce((previous, current) => { return previous + current.depense }, 0))
            setTotalBenefice(benefice?.reduce((previous, current) => { return previous + current.benefice }, 0))

        }
    }, [benefice])
    return (
        isloading ? <div className="loader"></div> : benefice?.length ? <div className="benefice">
            <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                <div className="col-lg-6">
                    <div className="h4 text-primary">FANSAN TEXTIL-COMPTABILITY</div>
                </div>
                <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                    <div className="col-lg-6 h4 text-secondary ">
                        Benefice
                    </div>
                    <div className="col-lg-1">
                        <button className="btn btn-primary" onClick={() => impression(printElement.current, 'landscape', "BENEFICES")}><i className="bx bx-printer"></i></button>
                    </div>
                </div>
            </div>
            <div className="table-responsive" ref={printElement}>
                <table className="table table-hover table-striped table-bordered">
                    <thead className="table-dark border-white text-center">
                        <tr className="align-middle text-center">
                            <th>N°</th>
                            <th>N°CONT</th>
                            <th>CONTENEUR</th>
                            <th>FOURNISSEUR</th>
                            <th>MONTANT ACHAT</th>
                            <th>MONTANT FACTURE</th>
                            <th>DEPENSE</th>
                            <th>BENEFICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benefice?.map((ben, ind) => (
                                <tr key={ind} className="align-middle ">
                                    <td>{ind + 1}</td>
                                    <td>{ben.numero}</td>
                                    <td>{ben.conteneur}</td>
                                    <td>{ben.fournisseur}</td>
                                    <td>{ben.montant_achat?.toLocaleString()}</td>
                                    <td>{ben.montant?.toLocaleString()}</td>
                                    <td>{ben.depense?.toLocaleString()}</td>
                                    <td>{ben.benefice?.toLocaleString()}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={4} className="text-center fw-bold fs-4">Total</td>
                            <td className="fw-bold">
                                {total.toLocaleString()} GNF
                            </td>
                            <td className="fw-bold">
                                {totalfacture.toLocaleString()} GNF
                            </td>
                            <td className="fw-bold">
                                {totaldepense.toLocaleString()} GNF
                            </td>
                            <td className="fw-bold">
                                {totalbenefice.toLocaleString()} GNF
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> : <div className="w-100 p-2 bg-danger-subtle text-danger fs-3 text-center"> Aucun benefice</div>
    );
}

export default Benefice;