import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Select from "react-select";
import UseFetch from "../components/UseFetch";


const ModalAddClient = ({ isnew, listClient }) => {
    const { data: clients, isloading: load } = UseFetch('clients');
    const { data: agents, isloading } = UseFetch('agents');
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const profile = decryptData('user_profile')
    const [dette, setDette] = useState(0);
    const [dettemax, setDetteMax] = useState(null);
    const [phone, setPhone] = useState(null)
    const [client, setClient] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [agent, setAgent] = useState(null);
    const [clientExist, setExist] = useState(false);

    const serachByTel = (e) => {
        let clt = clients?.find(clt => clt.telephone === e.target.value) ?? null;
        if (clt) {
            setClient(clt.client)
            setAdresse(clt.adresse)
            setDetteMax(clt.dettemax);
            setExist(true);
            setAgent({ 'label': clt.agent, value: '' })
            console.log(clt)
        } else {
            setClient(null)
            setAdresse(null)
            setExist(false);
            setDetteMax(0);
            setAgent(null)
        }
    }
    const handleSubmite = (e) => {

        e.preventDefault();
        if (!phone) {
            setErrors({ "telephone": "Entrez le téléphone du client" });
            return
        }
        if (!clientExist) {
            if (!client) {
                setErrors({ "client": "Entrez le nom du client" });
                return
            }
            if (!adresse) {
                setErrors({ "adresse": "Entrez l'adresse  du client" });
                return
            }
            if (!agent) {
                setErrors({ "agent": "Veuillez choisir un agent" });
                return
            }
        }
        setIsLoading(true);
        const formdata = new FormData(e.target);
        formdata.append('idmagasin', +profile.idmagasin);
        formdata.append('iduser', +profile.iduser);
        formdata.append('solde', dette);
        formdata.append('dettemax', dettemax);
        formdata.append('idagent', agent?.value);
        let status = 0;
        fetch(`${defaulUrl}clients/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade modal-lg" id="modalAddClient" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Créer un compte client</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)}>
                        <div className="modal-body position-relative">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="" className="fs-5">Téléphone</label>
                                        <input type="number" className="form-control hide-arrow" name="telephone" defaultValue={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            onBlur={(e) => serachByTel(e)} />
                                        {errors?.telephone && <span className="text-danger mt-2">{errors?.telephone}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="fs-5">Prénoms & Nom</label>
                                        <input type="text" className="form-control" name="client" defaultValue={client} disabled={clientExist}
                                            onChange={(e) => setClient(e.target.value)}
                                        />
                                        {errors?.client && <span className="text-danger mt-2">{errors?.client}</span>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="" className="fs-5">Adresse</label>
                                        <input type="text" className="form-control" name="adresse" defaultValue={adresse} disabled={clientExist}
                                            onChange={(e) => setAdresse(e.target.value)}
                                        />
                                        {errors?.adresse && <span className="text-danger mt-2">{errors?.adresse}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" >
                                        <label htmlFor="" className="fs-5">Agent responsable</label>
                                        <div className="input-group">
                                            {!clientExist ? <Select className="w-100"
                                                defaultInputValue={agent?.label}
                                                defaultValue={agent}
                                                options={
                                                    agents?.map((ag) => (
                                                        {
                                                            value: `${ag.id}`, label: `${ag.prenom} ${ag.nom}(${ag.telephone})`
                                                        }
                                                    ))
                                                }
                                                onChange={setAgent}
                                            /> : <input type="text" className="form-control" disabled defaultValue={agent?.label} />}

                                        </div>
                                        {errors?.agent && <span className="text-danger mt-2">{errors?.agent}</span>}
                                    </div>
                                    <div className="form-group" >
                                        <label htmlFor="" className="fs-5">Dette initiale</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                defaultValue={dette?.toLocaleString()}
                                                onChange={(e) => setDette(e.target.value.replace(/\D/g, ""))}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)}
                                                disabled={isnew}
                                            />
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                        </div>
                                        {errors?.solde && <span className="text-danger mt-2">{errors?.solde}</span>}
                                    </div>
                                    <div className="form-group" >
                                        <label htmlFor="" className="fs-5">Dette max</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                defaultValue={dettemax?.toLocaleString()}
                                                onChange={(e) => setDetteMax(e.target.value.replace(/\D/g, ""))}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)}

                                                disabled={clientExist} />
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                        </div>
                                        {errors?.solde && <span className="text-danger mt-2">{errors?.solde}</span>}
                                    </div>
                                </div>
                            </div>


                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                            <button type="submite" className="btn btn-success">Enregistrer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalAddClient;