import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const EditeDetteMax = ({ client }) => {
    const [paye, setPaye] = useState(0);
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);


    const handleSave = () => {
        if (!paye) {
            setErrors({ 'montant': "Entrez le nouveau montant!" });
            return
        }
        let status = 0;
        const formdata = new FormData();
        formdata.append('dettemax', paye);
        setIsLoading(true);
        fetch(`${defaulUrl}edite-dettemax/${client?.id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div>

            <div className="modal fade text-start" id="editeDetteMax" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content p-2">
                        <div className="modal-header">Modifier le credit max</div>
                        <form className="px-3" >
                            <div className="modal-body position-relative">
                                <label htmlFor="" className="fs-5 ">Ancien Montant</label>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" defaultValue={client?.dettemax?.toLocaleString()}
                                            disabled />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                </div>
                                <label htmlFor="" className="fs-5 ">Nouveau Montant</label>
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" defaultValue={paye}
                                            onFocus={onfocus}
                                            onBlur={formatMoney}
                                            onChange={(e) => {
                                                setPaye(e.target.value.replace(/\s/g, ""));
                                            }}
                                            placeholder="Entrez le nouveau montant" />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                    {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                    }
                                </div>
                                {isLoading && <Loading />}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                                <button type="button" className="btn btn-success" onClick={() => handleSave()}>Modifier</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditeDetteMax;