import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import UseFetch from "../components/UseFetch";
const ModalEditeCLient = ({ client, showDette }) => {
    const { data: agents, isloading } = UseFetch('agents');
    const ref = useRef();
    const user = decryptData("USER");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [dettemax, setDetteMax] = useState(0);
    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formdata = new FormData(e.target);
        formdata.append('dettemax', +formdata.get('dettemax').replace(/\D/g, ""))
        let status = 0;
        fetch(`${defaulUrl}client/edite/${client.id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade " id="modalEditeClient" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modifier client</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleSubmite(e)}>

                        <div className="modal-body position-relative">
                            <div className="row">

                                <div className="form-group">
                                    <label htmlFor="" className="fs-5">Téléphone</label>
                                    <input type="number" className="form-control hide-arrow" name="telephone" defaultValue={client?.telephone} />
                                    {errors?.telephone && <span className="text-danger mt-2">{errors?.telephone}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="fs-5">Prénoms & Nom</label>
                                    <input type="text" className="form-control" name="client" defaultValue={client?.client} />
                                    {errors?.client && <span className="text-danger mt-2">{errors?.client}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="" className="fs-5">Adresse</label>
                                    <input type="text" className="form-control" name="adresse" defaultValue={client?.adresse} />
                                    {errors?.adresse && <span className="text-danger mt-2">{errors?.adresse}</span>}
                                </div>


                                <div className="form-group" >
                                    <label htmlFor="" className="fs-5">Agent responsable</label>
                                    <div className="input-group">
                                        <select name="idagent" id="idagent" className="form-select"
                                            defaultValue={client?.agent} disabled={user.droit === 'Magasin'}>
                                            <option value={client?.idagent ?? ''}>{client?.agent ?? 'Choix'}</option>
                                            {agents?.map((ag, ind) => (
                                                <option value={ag.id} key={ind}>{`${ag.prenom} ${ag.nom}(${ag.telephone})`}</option>
                                            ))
                                            }

                                        </select>
                                    </div>
                                    {errors?.agent && <span className="text-danger mt-2">{errors?.agent}</span>}
                                </div>
                                <div className="form-group" >
                                    <label htmlFor="" className="fs-5">Dette max</label>
                                    <div className="input-group">
                                        <input type="text" id="dettemax" name="dettemax" className="form-control" defaultValue={client?.dettemax?.toLocaleString()}
                                            onChange={(e) => setDetteMax(e.target.value.replace(/\D/g, ""))}
                                            onBlur={(e) => formatMoney(e)}
                                            onFocus={(e) => onfocus(e)}
                                            disabled={user.droit === 'Magasin'}
                                        />
                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                    </div>
                                    {errors?.solde && <span className="text-danger mt-2">{errors?.solde}</span>}
                                </div>

                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                            <button type="submite" className="btn btn-success">Enregistrer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalEditeCLient;