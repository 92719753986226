import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const EditeMagasinName = ({ magasin }) => {
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [magasinName, setMagasinName] = useState(null)
    const handleSubmite = () => {
        if (!magasinName) {
            setErrors({ 'montant': "Entrez le nouveau nom!" });
            return
        }
        let status = 0;
        const formdata = new FormData();
        formdata.append('magasin', magasinName);
        setIsLoading(true);
        fetch(`${defaulUrl}magasin-edite/${magasin?.id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div>
            <div className="modal fade text-start" id="editeMagasin" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content p-2">
                        <div className="modal-header">Modifier un magasin</div>
                        <form className="px-3" >
                            <div className="modal-body position-relative">
                                <label htmlFor="" className="fs-5 ">Ancien Nom</label>
                                <div className="form-group">
                                    <input type="text" className="form-control" defaultValue={magasin?.mag}

                                        disabled />
                                </div>
                                <label htmlFor="" className="fs-5 ">Nouveau Nom</label>
                                <div className="form-group">

                                    <input type="text" className="form-control" defaultValue={magasinName}
                                        onChange={(e) => {
                                            setMagasinName(e.target.value);
                                        }}
                                        placeholder="Entrez le nouveau nom" />

                                    {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                    }
                                </div>
                                {isLoading && <Loading />}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                                <button type="button" className="btn btn-success" onClick={handleSubmite}>Modifier</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditeMagasinName;