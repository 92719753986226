
import { decryptData, defaulUrl, impression, Loading } from "../components/const";
import { useEffect, useRef, useState } from "react";
import UseFetch from '../components/UseFetch';
import Select from "react-select";
const VenteParConteneur = ({ id }) => {
    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    let { data: conteneurs, isloading } = UseFetch(`approvisions/vendu/${id}`);
    const [isLoading, setIsLoading] = useState(false);
    const [ventePeriode, setVentesPeriode] = useState(null);
    const [totalqte, setTotalQte] = useState(0);
    const [totalmontant, setTotalMontant] = useState(0);
    const [conteneur, setConteneur] = useState(null);
    const [error, setError] = useState(null);
    const [copieListe, setCopieListe] = useState(null);
    const [produits, setProduits] = useState(null);
    const [selectedProduit, setSelectedProd] = useState(null);
    const printElement = useRef()
    const handleShowVentesConteneur = (idconteneur) => {
        if (!conteneur) {
            setError('Veuillez choisir un conteneur');
            return
        } else {
            setError(null)
        }
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}ventes/conteneur/${idconteneur}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                da?.sort((a, b) => a.conteneur.localeCompare(b.conteneur))
                setVentesPeriode(da);
                setCopieListe(da);
                let prods = da?.map(p => ({ 'produit': p.produit }));
                setProduits([...new Map(prods?.map(o => [o.produit, o])).values()]);

            }
        })
    }
    const handleSearch = () => {
        let saisie = selectedProduit.value?.toUpperCase();
        if (saisie) {
            setVentesPeriode(copieListe?.filter((produit) => produit?.produit?.toUpperCase().includes(saisie)))
        } else {
            setVentesPeriode(copieListe);
        }
    }
    useEffect(() => {
        if (ventePeriode?.length) {
            setTotalQte(ventePeriode?.reduce((previous, current) => { return previous + current.quantite }, 0))
            setTotalMontant(ventePeriode?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [ventePeriode])
    return (
        <div className="container-fluid">
            <h3>LISTE DES VENTES PAR CONTENEUR</h3>
            <div className="row justify-content-between p-2 text-primary my-2">
                <div className="col-lg-6 modal-footer justify-content-start mx-2">
                    <div className="for-group w-75">
                        <label htmlFor="">Choisir un conteneur</label>
                        {isloading ? <Select /> :
                            <Select
                                defaultInputValue={conteneur?.label}
                                options={
                                    conteneurs.map((cont) => (
                                        {
                                            value: `${cont.id}`, label: `${cont.conteneur}`
                                        }
                                    ))
                                }
                                onChange={setConteneur}
                            />
                        }
                    </div>
                    <button type="submit" className="btn btn-primary mt-auto ms-1" onClick={() => handleShowVentesConteneur(conteneur?.value)}><i className="bx bx-show" ></i></button>
                    {error && <span className="text-danger">{error}</span>}
                </div>

                <div className="col-lg-4 form-group">
                    <label htmlFor="">Trier par produit</label>
                    {!produits ? <Select /> :
                        <div className="modal-footer">
                            <Select className="w-75"
                                defaultInputValue={selectedProduit?.label}
                                options={
                                    produits?.map((prod) => (
                                        {
                                            value: `${prod.produit}`, label: `${prod.produit}`
                                        }
                                    ))
                                }
                                onChange={
                                    setSelectedProd
                                }
                                onBlur={() => console.log(selectedProduit)}
                            />
                            <button type="submit" className="btn btn-primary mt-auto ms-1"
                                onClick={handleSearch}><i className="bx bx-search" ></i></button>
                        </div>
                    }
                </div>
            </div>

            {isLoading ? <div className="loader"></div> :
                <div className="row">
                    {ventePeriode?.length > 0 && <div className="col-lg-1 w-auto ms-auto">
                        <button className="btn btn-primary" onClick={() => impression(printElement.current, "landscape", `LISTE DES VENTES DU CONTENEUR: ${conteneur?.label?.toUpperCase()}`)}><i className="bx bx-printer"></i></button>
                    </div>}
                    {
                        (ventePeriode?.length) ?
                            <div className="table-responsive " ref={printElement}>
                                <table className="table  table-hover table-striped shadow table-bordered">
                                    <thead className="table-dark border-white">
                                        <tr>

                                            <th>PRODUIT</th>
                                            <th>QTE</th>
                                            <th>PVU</th>
                                            <th>MONTANT</th>
                                            <th>N°FACTURE</th>
                                            <th>CLIENT</th>
                                            <th>VENDEUR</th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody className="plage">
                                        {
                                            ventePeriode?.map((prod, ind) => (
                                                <tr key={ind} className="tr">
                                                    <td>{prod?.produit}</td>
                                                    <td>{prod?.quantite}</td>
                                                    <td>{prod?.pvu.toLocaleString()}</td>
                                                    <td>{prod?.montant.toLocaleString()}</td>
                                                    <td>{prod?.numero}</td>
                                                    <td>{prod?.client}</td>
                                                    <td>{prod?.vendeur?.toUpperCase()}</td>
                                                    <td>{prod?.created_at}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="fw-bold bg-secondary-subtle">
                                            <td className="text-center">Totaux</td>
                                            <td>{totalqte}</td>
                                            <td></td>
                                            <td>{totalmontant.toLocaleString()} GNF</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> : <div className="row justify-content-center fs-3 bg-danger-subtle text-danger p-2">Aucune vente effectuee</div>
                    }
                </div>
            }


        </div>


    );
}

export default VenteParConteneur;