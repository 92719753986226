import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, deleter, impression } from "../components/const";

const RapportComptable = () => {
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsloading] = useState(false);
    const [donnees, setData] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [totalVersement, setVersements] = useState(0);
    const [totaRemboursement, setRemboursement] = useState(0);
    const [totalEntree, setTotalEntree] = useState(0);
    const [totalDepense, setTotalDepense] = useState(0);
    const [totalSortie, setTotalSortie] = useState(0)
    const refBtnSend = useRef();
    const ref=useRef();
    const handleSend = () => {
        setIsloading(true);
        let status = 0;
        fetch(`${defaulUrl}comptable/rapport/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsloading(false);
            if (status === 200) {
                setData(da)

            }
        })
    };

    const encaissements = donnees?.encaissements;
    const remboursements = donnees?.remboursements;
    const depenses = donnees?.depenses;
    const entree = donnees?.entree;
    const sorties = donnees?.sorties;
    useEffect(() => {
        if (firstLoad) {
            handleSend();
            setFirstLoad(false)
        }
    }, [firstLoad])
    useEffect(() => {
        if (encaissements?.length) {
            setVersements(encaissements?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
        if (remboursements?.length) {
            setRemboursement(remboursements?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
        if (depenses?.length) {
            setTotalDepense(depenses?.reduce((previous, current) => { return previous + current.depense }, 0))
        }
        if (entree?.length) {
            setTotalEntree(entree?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
        if (sorties?.length) {
            setTotalSortie(sorties?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [encaissements, remboursements, depenses, entree, sorties])

    return (
        <div className="rapport">
            <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                <div className="col-lg-6">
                    <div className="h4 text-primary">FANSAN TEXTIL-COMPTABILITY</div>
                </div>
                <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                    <div className="col-lg-6 h4 text-secondary ">
                        Rapport Comptable
                    </div>
                    <div className="col-lg-6  ">
                        <div className="modal-footer align-items-center justify-content-end ">
                            <div className="col-lg-4 m-1 text-end ">
                                <input type="date" className="form-control " id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                            </div>
                            <div className="col-lg-4 m-1 text-end">
                                <input type="date" className="form-control " id="date1" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                            </div>
                            <div className="col-lg-2  text-end w-auto">
                                <button ref={refBtnSend} type="submit" className="btn btn-primary" onClick={() => handleSend()}><i className="bx bx-search"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row justify-content-end p-2">
                    <div className="col-lg-1 text-end">
                        <button className="btn btn-primary" onClick={() => impression(ref.current, 'landscape',"RAPPORT DE LA COMPTABILITE")}>
                            <i className="bx bx-printer"></i></button>
                    </div>
                </div>
            </div>
            {(!donnees || isLoading) && <div className="loader"></div>}
            {(donnees) && <div className="table-responsive shadow p-3" ref={ref}>
                <h5 className="p-2 fw-bold text-primary">Encaissements magasins</h5>
                {encaissements?.length ? <table className="table table-striped ">
                    <thead className="table-success">
                        <tr>
                            <th>N°</th>
                            <th>MAGASINS</th>
                            <th>MONTANT</th>
                            <th>DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            encaissements?.map((enc, ind) =>
                                <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{enc.magasin}</td>
                                    <td>{enc.montant?.toLocaleString()}</td>
                                    <td>{enc.created_at}</td>
                                </tr>
                            )
                        }
                        <tr className="fw-bold bg-secondary-subtle">
                            <td colSpan={2}>TOTAL</td>
                            <td>{totalVersement?.toLocaleString()} GNF</td>
                        </tr>
                    </tbody>
                </table> : <div className="row justify-content-center mx-3 fs-3  bg-danger-subtle text-danger">Aucun encaissement trouvé</div>}

                <h5 className="p-2 fw-bold text-primary">Les entrees de caisse</h5>

                {entree?.length ?
                    <table className="table table-striped">
                        <thead className="table-success">
                            <tr>
                                <th>N°</th>
                                <th>DESCRIPTION</th>
                                <th>MONTANT</th>
                                <th>TYPE</th>
                                <th>DATE</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                entree.map((opt, ind) =>
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{opt.description}</td>
                                        <td>{opt.montant?.toLocaleString()}</td>
                                        <td>{opt.type}</td>
                                        <td>{opt.created_at} GNF</td>
                                        <td className="text-end">
                                            <button hidden className="btn btn-primary m-1"><i className="bx bx-edit"></i></button>

                                            <button className="btn btn-danger m-1" onClick={() => deleter(`comptable/delete/${opt.id}`)}><i className="bx bx-trash"></i></button>

                                        </td>
                                    </tr>
                                )
                            }
                            <tr className="fw-bold bg-secondary-subtle" >
                                <td colSpan={2} className="text-center">TOTAL</td>
                                <td>{ totalEntree?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table> : <div className="row justify-content-center mx-3 fs-3 bg-danger-subtle text-danger">Aucune entrée effectuee</div>}

                <h5 className="p-2 fw-bold text-primary">Depenses sur conteneurs</h5>
                {depenses?.length ?
                    <table className="table table-striped">
                        <thead className="table-danger">
                            <tr>
                                <th>N°CONT</th>
                                <th>MONTANT</th>
                                <th>CONTENEUR</th>
                                <th>DESCRIPTION</th>
                                <th>DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                depenses?.map((dep, ind) =>
                                    <tr key={ind}>
                                        <td>{dep.numero}</td>
                                        <td>{dep.depense?.toLocaleString()}</td>
                                        <td>{dep.conteneur}</td>
                                        <td>{dep.description}</td>
                                        <td>{dep.created_at} </td>
                                    </tr>
                                )
                            }
                            <tr className="fw-bold bg-secondary-subtle">
                                <td >TOTAL</td>
                                <td>{totalDepense?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table> : <div className="row justify-content-center mx-3 fs-3 bg-danger-subtle text-danger">Aucune depense effectuee</div>}
                <h5 className="p-2 fw-bold text-primary">Remboursements Fournisseurs</h5>
                {remboursements?.length ?
                    <table className="table table-striped">
                        <thead className="table-danger">
                            <tr className="align-middle">
                                <th>N°</th>
                                <th>FOURNISSEURS</th>
                                <th>MONTANT</th>
                                <th>MODE PAIEMENT</th>
                                <th>N°FACTURE</th>
                                <th>DATE</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                remboursements?.map((remb, ind) =>
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{remb.fournisseur}</td>
                                        <td>{remb.montant?.toLocaleString()}</td>
                                        <td>{remb.source}</td>
                                        <td>{remb.numero}</td>
                                        <td>{remb.created_at}</td>
                                        <td className="text-end">
                                            <button hidden className="btn btn-primary m-1"><i className="bx bx-edit"></i></button>
                                            <button className="btn btn-danger m-1" onClick={() => deleter(`paiement/delete/${remb.id}`)}><i className="bx bx-trash" ></i></button>
                                        </td>
                                    </tr>
                                )
                            }
                            <tr className="fw-bold bg-secondary-subtle">
                                <td colSpan={2}>TOTAL</td>
                                <td>{totaRemboursement?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table> : <div className="row justify-content-center mx-3 fs-3 bg-danger-subtle text-danger">Aucun remboursement effectue</div>}


                <h5 className="p-2 fw-bold text-primary">Sorties et versements banquaires</h5>

                {sorties?.length ?
                    <table className="table table-striped">
                        <thead className="table-danger">
                            <tr>
                                <th>N°</th>
                                <th>DESCRIPTION</th>
                                <th>MONTANT</th>
                                <th>TYPE</th>
                                <th>DATE</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sorties?.map((opt, ind) =>
                                    opt.type !== 'entree' && <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{opt.description}</td>
                                        <td>{opt.montant?.toLocaleString()} GNF</td>
                                        <td>{opt.type}</td>
                                        <td>{opt.created_at} </td>
                                        <td className="text-end">
                                            <button hidden className="btn btn-primary m-1"><i className="bx bx-edit"></i></button>

                                            <button className="btn btn-danger m-1" onClick={() => deleter(`comptable/delete/${opt.id}`)}><i className="bx bx-trash"></i></button>

                                        </td>
                                    </tr>
                                )
                            }
                            <tr className="fw-bold bg-secondary-subtle">
                                <td colSpan={2}>TOTAL</td>
                                <td>{ totalSortie?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table> : <div className="row justify-content-center mx-3 fs-3 bg-danger-subtle text-danger">Aucune sortie effectuee</div>}
            </div>
            }
        </div>

    );
}

export default RapportComptable;