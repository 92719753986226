import { NavLink } from "react-router-dom";
import { handleMenu } from "./menu";
import { handeLeaveSideBar, handeOnHoverSideBar } from "../components/const";
const MenuAdnin = () => {
    return (
        <ul className="metismenu " id="menu" onMouseOver={() => handeOnHoverSideBar()} onMouseLeave={() => handeLeaveSideBar()}>
            <li>
                <NavLink to='/'>
                    <div className="parent-icon"><i className='bx fs-4 bx-home-circle'></i>
                    </div>
                    <div className="menu-title">Tableau de bord</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/magasins/liste'>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-store "></i>
                    </div>
                    <div className="menu-title">Gestion Magasins</div>
                </NavLink>
            </li>
            <li className="dropdwon">
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon">

                        <i className="bx fs-4 bx-category ms-2"></i>
                    </div>
                    <div className="menu-title">
                        Fournisseurs
                    </div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Fornisseurs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Fournisseur
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>

            <li className="dropdwon" >
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title">Clients</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/clients-all' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Tous les clients
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon" >
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title">Personnel-Agents</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/personnel'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Personnel
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/agents'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Agents
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/transitaires'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Trasitaires
                        </NavLink>
                    </li>

                </ul>
            </li>
            <li>
                <NavLink to='/depenses'>
                    <i className="bx fs-4 bx-right-arrow-alt"></i>Dépenses conteneurs
                </NavLink>
            </li>
            <li>
                <NavLink to='/factures/suppression' >
                    <i className="bx fs-4 bx-right-arrow-alt"></i>Suppression factures
                </NavLink>
            </li>
            <li>
                <NavLink to='/caisse'>
                    <i className="bx fs-4 bx-right-arrow-alt"></i>Rapport magasins
                </NavLink>
            </li>
            <li>
                <NavLink to='/comptability-rapport'>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-line-chart "></i>
                    </div>
                    <div className="menu-title">Rapport comptable</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/users'>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-user-circle"></i>
                    </div>
                    <div className="menu-title">Sessions users</div>
                </NavLink>
            </li>





        </ul>
    );
}
export default MenuAdnin;