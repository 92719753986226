import { useEffect, useState } from "react";

const DetailFactures = ({ produits }) => {
    const [totalqte, setTotalQte] = useState(0);
    const [totalmontant, setTotalMontant] = useState(0);
   

    useEffect(() => {
        if (produits) {
            setTotalQte(produits?.reduce((previous, current) => { return previous + current.quantite }, 0));
            setTotalMontant(produits?.reduce((previous, current) => { return previous + (current.pvu * current.quantite) }, 0));
        }
    }, [produits])


    return (
       
            <div className="modal-body">
                {
                   <div className="table-responsive">
                        <table className="table-table-hover table-bordered table striped">
                            <thead className="table-primary text-center">
                                <tr>
                                    <th>N°</th>
                                    <th>PRODUIT</th>
                                    <th>QUANTITE</th>
                                    <th>PVU</th>
                                    <th>MONTANT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    produits?.map((prod, ind) => (
                                        <tr key={ind}>
                                            <td>{ind + 1}</td>
                                            <td>{prod.produit}</td>
                                            <td>{prod.quantite}</td>
                                            <td>{prod.pvu.toLocaleString()}</td>
                                            <td>{(prod.pvu * prod.quantite).toLocaleString()}</td>
                                        </tr>
                                    ))
                                }
                                <tr className="fw-bold bg-secondary-subtle">
                                    <td colSpan={2} className="text-center">Totaux</td>
                                    <td>{totalqte.toLocaleString()}</td>
                                    <td></td>
                                    <td>{totalmontant.toLocaleString()} GNF</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                }
            </div>
       
    );
}

export default DetailFactures;