import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, impression } from "../components/const";
import Select from "react-select";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import EditePaieFacture from "../Ventes/EditePaieFacture";
import EditePaieDette from "../Clients/EditePaieDette";
import EditeAvance from "../Clients/EditeAvance";
import EditeVersement from "../Magasins/EditeVersement";
import EditeOpration from "../Magasins/EditeOperation";

const Caisse = () => {
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const profile = decryptData("user_profile");
    const [isLoading, setIsLoading] = useState(false);
    const user = decryptData("USER");
    const { data: magasins, isloading } = UseFetch('magasins/liste');
    const [rapport, setRapport] = useState(null);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalRemb, setTotalRemb] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalAvance, setTotalAvance] = useState(0);
    const [totalEntree, setTotalEntree] = useState(0);
    const [totalSortie, setTotalSortie] = useState(0);
    const [totalVersement, setTotalVersement] = useState(0);
    const [selectPaie, setSelectPaie] = useState(null)
    const [selectedMagasin, setSelectedMag] = useState(profile ? { "value": profile?.idmagasin, "label": profile?.magasin } : null);
    const printElement = useRef();
    const handleShow = () => {
        if (!selectedMagasin) {
            Swal.fire({
                title: "Erreur",
                text: "Veuillez choisir un magasin",
                icon: 'error'
            })
            return
        }
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}rapport/magasin/${selectedMagasin?.value}/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                setRapport(da);
            }
        })
    }
    useEffect(() => {
        if (user.droit === 'Magasin') {
            handleShow();
        }
    }, [isloading]);
    useEffect(() => {
        if (rapport) {
            setTotalPaye(rapport?.payes?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalRemb(rapport?.remboursements?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalCredit(rapport?.dettes?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalAvance(rapport?.avances?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalEntree(rapport?.entrees?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalSortie(rapport?.sorties?.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalVersement(rapport?.versements?.reduce((previous, current) => { return previous + current.montant }, 0));
        }
    }, [rapport]);



    return (
        <div className="caisse">
            {isLoading && <div className="loader"></div>}
            <div className="row align-items-center">
                <div className="h2 col-lg-4" >RAPPORT MAGASIN</div>
                <div className="col-lg-8 modal-footer">
                    {
                        (user.droit != "Magasin") && <div className="col-lg-3">
                            {isloading ? <Select /> :
                                <Select className="w-100"
                                    defaultInputValue={selectedMagasin?.label}
                                    options={
                                        magasins?.map((mag) => (
                                            {
                                                value: `${mag.id}`, label: `${mag.mag}`
                                            }
                                        ))
                                    }
                                    onChange={setSelectedMag}
                                />
                            }
                        </div>
                    }

                    <input type="date" className="form-control w-auto m-1" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />


                    <input type="date" className="form-control w-auto m-1" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />

                    <button type="submit" className="btn btn-primary m-1  align-self-end" onClick={handleShow}>
                        <i className="bx bx-search" ></i></button>
                    <button type="submit" className="btn btn-primary m-1  align-self-end" onClick={() => impression(printElement.current, 'portrait', "RAPPORT MAGASIN")}>
                        <i className="bx bx-printer" ></i></button>


                </div>
            </div>
            {(!rapport) ? <div></div> :
                <div className="row" ref={printElement}>
                    <div className="table-responsive mt-2">
                        <h3 className="text-primary">LES ENTREES DE CAISSE</h3>
                        {(rapport?.payes?.length > 0) && <>
                            <h4 className="mt-2">Ventes en espces</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-success">
                                    <tr>
                                        <th>N°FACTURES</th>
                                        <th>MONTANT</th>
                                        <th>CLIENT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.payes?.map((paie, ind) => (
                                            <tr key={ind}>
                                                <td>{paie.numero}</td>
                                                <td>{paie.montant?.toLocaleString()} GNF</td>
                                                <td>{paie.client}</td>
                                                <td>{paie.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {paie.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaie' onClick={() => setSelectPaie(paie)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalPaye?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                        {(rapport?.remboursements?.length > 0) && <>
                            <h4 className="mt-2">Remboursements factures</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-success">
                                    <tr>
                                        <th>N°FACTURES</th>
                                        <th>MONTANT</th>
                                        <th>CLIENT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        rapport?.remboursements?.map((remb, ind) => (
                                            <tr key={ind}>
                                                <td>{remb.numero}</td>
                                                <td>{remb.montant?.toLocaleString()} GNF</td>
                                                <td>{remb.client}</td>
                                                <td>{remb.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {remb.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaie' onClick={() => setSelectPaie(remb)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalRemb?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                        {(rapport?.dettes?.length > 0) && <>
                            <h4 className="mt-2">Remboursements dettes initiales</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-success">
                                    <tr>
                                        <th>CLIENT</th>
                                        <th>MONTANT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.dettes?.map((remb, ind) => (
                                            <tr key={ind}>
                                                <td>{remb.client}</td>
                                                <td>{remb.montant?.toLocaleString()} GNF</td>
                                                <td>{remb.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {remb.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaieDette' onClick={() => setSelectPaie(remb)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalCredit?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                        {(rapport?.avances?.length > 0) && <>
                            <h4 className="mt-2">Versement avances client</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-success">
                                    <tr>
                                        <th>CLIENT</th>
                                        <th>MONTANT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.avances?.map((av, ind) => (
                                            <tr key={ind}>
                                                <td>{av.client}</td>
                                                <td>{av.montant?.toLocaleString()} GNF</td>
                                                <td>{av.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {av.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeAvance' onClick={() => setSelectPaie(av)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalAvance?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                        {(rapport?.entrees?.length > 0) && <>
                            <h4 className="mt-2">Entrées de caisse</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-success">
                                    <tr>
                                        <th>DESCRIPTION</th>
                                        <th>MONTANT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.entrees?.map((ent, ind) => (
                                            <tr key={ind}>
                                                <td>{ent.description}</td>
                                                <td>{ent.montant?.toLocaleString()} GNF</td>
                                                <td>{ent.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {ent.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeOperation' onClick={() => setSelectPaie(ent)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalEntree?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                    </div>
                    <div className="table-responsive mt-2">
                        <h3 className="text-primary">LES SORTIES DE CAISSE</h3>
                        {(rapport?.versements?.length > 0) && <>
                            <h4 className="mt-2">Versement à la Comptabilité</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-danger">
                                    <tr>
                                        <th>MONTANT</th>
                                        <th>STATUT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" && <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.versements?.map((vers, ind) => (
                                            <tr key={ind}>
                                                <td>{vers.montant?.toLocaleString()} GNF</td>
                                                <td>{vers.status}</td>
                                                <td>{vers.created_at}</td>
                                                {(user.droit === "Magasin" && vers.status != "confirmé") && <td className="text-end">
                                                    {vers.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeVers' onClick={() => setSelectPaie(vers)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">

                                        <td>TOTAL:  {totalVersement?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                        {(rapport?.sorties?.length > 0) && <>
                            <h4 className="mt-2">Sortie de caisse</h4>
                            <table className="table table-bordered table-stripped my-2">
                                <thead className="table-danger">
                                    <tr>
                                        <th>DECRIPTION</th>
                                        <th>MONTANT</th>
                                        <th>DATE</th>
                                        {user.droit === "Magasin" &&
                                            <th className="text-end">ACTION</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rapport?.sorties?.map((sortie, ind) => (
                                            <tr key={ind}>
                                                <td>{sortie.description}</td>
                                                <td>{sortie.montant?.toLocaleString()} GNF</td>
                                                <td>{sortie.created_at}</td>
                                                {user.droit === "Magasin" && <td className="text-end">
                                                    {sortie.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeOperation' onClick={() => setSelectPaie(sortie)}>
                                                        <i className="bx bx-edit"></i>
                                                    </button>
                                                    }
                                                </td>}
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-secondary-subtle fw-bold ">
                                        <td>TOTAL</td>
                                        <td>{totalSortie?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        }

                    </div>
                </div>
            }

            <EditePaieFacture paie={selectPaie} />
            <EditePaieDette paie={selectPaie} />
            <EditeAvance paie={selectPaie} />
            <EditeVersement paie={selectPaie} />
            <EditeOpration paie={selectPaie} />
        </div>
    );
}

export default Caisse;